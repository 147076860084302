import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NotFoundPage = () => (
  <Layout>
  <div className="404-page" style={{padding: "30px"}}>
    <SEO title="404: Not found" />
    <h1>Página no encontrada</h1>
    <p>Acabas de llegar a una ruta que no existe.</p>
    </div>
  </Layout>
)

export default NotFoundPage
